html,
body {
    padding: 0;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42857143;
    color: #777777;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.counter {
    padding: 2px 3px;
    border-bottom: 3px solid lightgreen;
}

.paid_image {
    position: absolute;
    top: 0;
    width: 300px;
    margin-left: 40%;
    margin-top: 50px;
    opacity: 0.3;
}

.payments_list {
    list-style-type: none
}

#print_route .border {
    border: 1px solid #000;
    border-top: none;
}

#print_route .border-bottom {
    border-bottom: 1px solid #000;
}

#print_route .border-top {
    border-top: 1px solid #000;
}

.price {
    float: right;
}

.single-service {
}

.single-service:nth-child(odd) {
    background-color: #f3f3f3;
}

.service-details {
    padding-bottom: 10px;
}

.header-row {
    font-size: 15px;
    padding: 10px 0px;
}

.assets {
    padding: 10px 0px;
}

.trees {
    padding: 1px 1px;
    border-bottom: 2px solid darkgray;
}

h6 {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.1;
    color: #444444;
    font-size: 12px;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.align-baseline {
    align-self: baseline;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

.d-inherit {
    display: inherit;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.avoid_page_breaking_inside {
    page-break-inside: avoid !important;
    break-inside: avoid !important;
}

.page_breaking_inside {
    page-break-inside: auto !important;
    break-inside: auto !important;
}

.preserve-text-area-format {
    white-space: pre-line;
}

.vertical-top {
    vertical-align: top;
}

.width-90 {
    width: 90%;
    display: inline-block;
}

.width-75 {
    width: 75%;
    display: inline-block;
}

.width-50 {
    width: 50%;
    display: inline-block;
}

.width-33 {
    width: 33.33333333%;
    display: inline-block;
}

.width-25 {
    width: 25%;
    display: inline-block;
}

.width-16 {
    width: 16.66666667%;
    display: inline-block;
}

.width-10 {
    width: 10%;
    display: inline-block;
}

.width-8 {
    width: 8.33333333%;
    display: inline-block;
}

.p-5 {
    padding: 5px;
}

.p-top10 {
    padding-top: 10px;
}

.p-right {
    padding-right: 25px;
}

.p-right10 {
    padding-right: 10px;
}

.p-left {
    padding-left: 25px;
}

.top50 {
    margin-top: 50px !important;
}

.top25 {
    margin-top: 25px !important;
}

.bottom25 {
    margin-bottom: 25px !important;
}

.bottom10 {
    margin-bottom: 25px !important;
}

.mt10 {
    margin-top: 10px;
}

.mt5 {
    margin-top: 5px;
}

.ml10 {
    margin-left: 10px;
}

.mr10 {
    margin-right: 10px;
}

.margin-left-auto {
    margin-left: auto;
    display: inline-block;
}

.margin10 {
    margin: 10px !important;
}

.customer-section-header-margin {
    margin: 10px 10px 10px 15px !important;
}

.text-black {
    color: black;
}

.bolder {
    font-weight: 600;
}

.text-primary {
    color: #d9230f;
}

.text-success {
    color: #468847;
}

.asset-wrapper-inline {
    padding: 4px 4px;
    display: inline-block;
    vertical-align: top;
}

.charts-wrapper {
    display: inline-block;
    width: 100%;
}

.single-chart {
    display: inline-block;
    width: 33%;
    vertical-align: top;
}

.diagram {
    display: inline-block;
    vertical-align: top;
}

.legend {
    vertical-align: top;
    margin-top: 30px;
    display: inline-block;
    align-items: center;
    width: 150px;
}

.legend span {
    margin-left: 3px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 9px;
}

.asset-details-vertically > span {
    display: block;
}

.thumbnails-vertically {
    margin: 4px 2px;
    display: block;
    page-break-inside: avoid;
}

.thumbnails-horizontally {
    margin: 0px 2px;
    display: inline-block;
    page-break-inside: avoid;
}

.photo {
    margin-bottom: 10px;
    width: fit-content;
}

.photo-image {
    padding: 1px 1px 0px 1px;
    border: 1px solid #dddddd;
}

.photo-text {
    border: 1px solid #dddddd;
    border-spacing: 0;
    border-top: none;
    padding: 10px 0px;
}

.photo img {
    max-height: 1200px;
    max-width: 968px;
}

#print_proposal {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: left !important;
}

#print_proposal h3 {
    font-size: 1.8em;
}

#print_proposal h4 {
    font-size: 1.4em !important;
}

#print_proposal h5 {
    font-size: 1em;
}

#print_proposal .title {
    font-size: 1.4em;
}

#print_proposal .header-row {
    font-size: 1.2em;
    padding: 10px 0px;
}

#print_route {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 30px;
}

.print_route {
    margin-bottom: 30px;
}

.print_route h6 {
    margin: 3px 0;
}

.print_route h2 {
    margin: 0 5px;
}

@media (min-width: 2400px) {
    #print_proposal {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: left !important;
        width: 2000px !important;
    }
}

@media (min-width: 1200px) {
    #print_proposal {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: left !important;
        width: 1170px;
    }
}

@media (min-width: 992px) {
    #print_proposal {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: left !important;
        width: 970px;
    }
}

@media (min-width: 768px) {
    #print_proposal {
        width: 750px;
    }
}

@media (max-width: 425px) {

    #print_proposal {
        width: 90vw;
    }


    #print_proposal .arborist-info {
        display: block;
        margin: 0 !important;
    }

    #print_proposal .proposal-info {
        text-align: left !important;
        padding: 0;
    }
}

@media (max-width: 425px) {

    #print_work_order {
        width: 90vw;
        padding: 0 !important;
    }

    #print_work_order .fontSize8 h6 {
        font-size: 8px !important;
    }

    .dott-overflow {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    #print_work_order .mobile-text-left {
        text-align: left !important;
    }

    #print_work_order .pad-left {
        padding: 0 !important;
    }
}

.text-danger {
    color: #b94a48;
    font-size: 13px;
    font-weight: 500;
}

.text-warning {
    color: #c09853;
    font-size: 13px;
}

.text-header {
    color: #444444;
    margin: 9px 0;
}

.floating-box {
    display: inline-block;
    margin: 10px;
    border: 1px solid;
}


.flex-wrap {
    display: flex !important;
    flex-wrap: wrap !important;
}

.pdf-flex {
    display: -webkit-box;
}

.display-block {
    display: block;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.width-inherit {
    width: inherit;
}

.panel {
    margin-bottom: 18px;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-default {
    border-color: #dddddd;
    padding: 15px;
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-default > .panel-heading {
    color: #444444;
    background-color: #fcfcfc;
    border-color: #dddddd;
}


.arborist-info-col {
    width: 50%;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.arborist-info-text {
    float: right;
    margin-right: 20px;
}

.square {
    border: 1px solid;
    display: inline-block;
    vertical-align: bottom;
}

.proposal-page-header {
    padding: 24px 0;
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
}

.invoice-page-header {
    padding: 24px 0;
    border-top: 1px solid #aaaaaa;
    border-bottom: 1px solid #aaaaaa;
}

.font-weight-bold {
    font-weight: bold;
}

.header-fixed {
    width: 100%;
    border-collapse: unset;
    border-radius: 4px;
    border-spacing: 0;
}

.header-fixed > thead {
    background-color: #fcfcfc;
    border: 1px solid #dddddd;
    border-bottom: 0;
}

.terms-and-conditions {
    page-break-before: always !important;
}

.site-maps {
    page-break-inside: avoid;
    break-inside: avoid;
}

.relative {
    position: relative;
}

@media print {
    .hidden-print {
        display: none !important;
    }
}

.proposal-service-status-declined {
    color: red;
}

.proposal-service-status-accepted {
    color: green;
}